@use "../../theme/_variables.scss" as vars;

.title {
  text-align: center;
  width: 85vw;
  margin: 0 auto;
  padding-bottom: 64px;
  font-size: 2rem;
  font-weight: 800;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  height: 100vh;

  --padding-top: 80px;
  --padding-bottom: 80px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.error-message {
  padding-left: 16px;
  color: vars.$red;
  font-weight: 600;
}

.CGU {
  display: flex;
  gap: 16px;

  input {
    margin-left: 16px;
    width: 24px;
    height: 24px;
  }

  a {
    color: vars.$accent-400;
  }
}

.submit {
  margin-top: 40px;
}
