.refresh-button{
    width: 150px;
    height: 80%;
}

.header{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    padding: 10px 0;
}

.content{
    max-width: 80%;
    margin: 0 auto;
    
}