@use "../../../../theme/variables" as vars;

.input-container {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  gap: 12px;
  width: 100%;
  background: linear-gradient(45deg, rgba($color: vars.$white, $alpha: 0.4), rgba($color: vars.$light-primary-600, $alpha: 0.16));
  border-radius: 16px;
  box-shadow: 0 12px 32px rgba($color: vars.$primary-900, $alpha: 0.18);

  &.round {
    border-radius: 100px;

  }

  svg {
    display: flex;
    height: 28px;
    min-width: 28px;
    aspect-ratio: 1;
  }

  .input {
    border: 0;
    flex: 1;
    background: transparent;
    outline: none;
    font-size: 1.1rem;
    min-width: 10px;
    font-weight: 600;

    color: rgba($color: vars.$primary-400, $alpha: 0.8);

    &::placeholder {
      color: rgba($color: vars.$primary-400, $alpha: 0.5);
    }
  }

  .showIcon {
    height: 28px;
    aspect-ratio: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

:global(.dark) {

  .input-container {
    background: linear-gradient(45deg, rgba($color: vars.$light-primary-600, $alpha: 0.16), rgba($color: vars.$white, $alpha: 0.24));
    box-shadow: 0 16px 40px rgba($color: vars.$white, $alpha: 0.08);
  }

  .input {


    color: rgba($color: vars.$white, $alpha: 0.8);

    &::placeholder {
      color: rgba($color: vars.$white, $alpha: 0.5);
    }
  }
}